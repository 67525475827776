<script>
import axios from 'axios';
import Table from "@/components/Table.vue";
import TaskQuery from "@/components/AdminSQLTables/TaskQuery.vue";
import FreePlanQuery from "@/components/AdminSQLTables/FreePlanQuery.vue";
import LoginsQuery from "@/components/AdminSQLTables/LoginsQuery.vue";
import UsersQuery from "@/components/AdminSQLTables/UsersQuery.vue";
import UserAnswersQuery from "@/components/AdminSQLTables/UserAnswersQuery.vue";
import LoginModal from "@/components/LoginModal.vue";

export default {
  name: "adminSQL" ,
  components: {UserAnswersQuery, UsersQuery, LoginsQuery, LoginModal, FreePlanQuery, Table, TaskQuery },
  data() {
    return {
      queries: [
        {
          name: 'Task Log',
          value: 'taskLog'
        },
        {
          name: 'FreePlan Log',
          value: 'freePlanLog'
        },
        {
          name: 'Users',
          value: 'users'
        },
        {
          name: 'Logins',
          value: 'logins'
        },
        {
          name: 'User Answers',
          value: 'userAnswers'
        }
      ],
      query: '',
      user: {},
      showTable: '',
      records: [],
      showTaskLog: false,
      showFreePlanLog: false,

    }
  },
  mounted() {
    const adminQuery = localStorage.getItem('adminQuery');
    if (adminQuery) {
      this.query = JSON.parse(adminQuery);
      if(this.query) {
        this.handleQueryChange(this.query);
      }
    }
  },
  methods: {
    async handleQueryChange(event){
      let name = this.query ? this.query.value : event.value.value;
      const token = this.getCookie('lz_token');
      const response = await axios.get('/tasks/getAdminQuery', {
          params: {
            token: token,
            query: name
          }
        });
      this.records = response.data;
      this.records.forEach(record => {
        record.createdDate = new Date(record.createdDate).toLocaleString('pl-PL');
        record.expiredDate = new Date(record.expiredDate).toLocaleString('pl-PL');
        if(record.subExpirationDate) {
          record.subExpirationDate= new Date(record.subExpirationDate).toLocaleString('pl-PL');
        }
      });
      if(name === 'taskLog') {
        this.showTable = 0;
      } else if(name === 'freePlanLog') {
        this.showTable = 1;
      } else if(name === 'users') {
        this.showTable = 2;
      } else if(name === 'logins') {
        this.showTable = 3;
      } else if(name === 'userAnswers') {
        this.showTable = 4;
      }
      localStorage.setItem('adminQuery', JSON.stringify(this.query));
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="table-body">
    <div class="mb-4 mt-2">
      <Dropdown v-model="query" :options="queries" optionLabel="name" placeholder="Query" @change="handleQueryChange" class="input"/>
    </div>
    <template v-if="showTable === 0">
      <TaskQuery :records="records"></TaskQuery>
    </template>
    <template v-else-if="showTable === 1">
      <FreePlanQuery :records="records"></FreePlanQuery>
    </template>
    <template v-else-if="showTable === 2">
      <UsersQuery :records="records"></UsersQuery>
    </template>
    <template v-else-if="showTable === 3">
      <LoginsQuery :records="records"></LoginsQuery>
    </template>
    <template v-else-if="showTable === 4">
      <UserAnswersQuery :records="records"></UserAnswersQuery>
    </template>
  </div>
</template>

<style scoped>

.table-body {
  width: 100%;
}
.input {
  width: 15em;
  margin-right: 2rem;
}
</style>