const types = [
    {
        id: '1',
        name: 'p',
        label: 'Podstawa',
        isDisabled: false
    },
    {
        id: '2',
        name: 'r',
        label: 'Rozszerzenie',
        isDisabled: false
    }
];

const typesForAdmin = [
    {
        id: '0',
        name: undefined,
        label: '--Brak--',
        isDisabled: false
    },
    {
        id: '1',
        name: 'p',
        label: 'Podstawa',
        isDisabled: false
    },
    {
        id: '2',
        name: 'r',
        label: 'Rozszerzenie',
        isDisabled: false
    }
];

const units = [
    {
        id: '111',
        name:'ciag',
        label: 'Ciagi liczbowe'
    },
    {
        id: '1111',
        name: 'rroz',
        label: 'Elementy analizy matematycznej (Rozszerzenie)'
    },
    {
        id: '112',
        name:'fkw',
        label: 'Funkcja kwadratowa'
    },
    {
        id: '113',
        name:'flin',
        label: 'Funkcja liniowa'
    },
   /* {
        id: '114',
        name:'flog',
        label: 'Funkcja logarytmiczna'
    },*/
    {
        id: '115',
        name:'fwyk',
        label: 'Funkcja wykładnicza'
    },
    {
        id: '116',
        name: 'fwym',
        label: 'Funkcja wymierna'
    },
    {
        id: '117',
        name: 'func',
        label: 'Funkcje'
    },
    {
        id: '118',
        name: 'geoan',
        label: 'Geometria analityczna'
    },
    {
        id: '119',
        name: 'geoprz',
        label: 'Geometria przestrzenna'
    },
    {
        id: '120',
        name: 'geop1',
        label: 'Geometria płaska - pojecia wstępne'
    },
    {
        id: '121',
        name: 'geop',
        label: 'Geometria płaska'
    },
    {
        id: '122',
        name: 'komb',
        label: 'Kombinatoryka'
    },
    {
        id: '123',
        name: 'liczidzia',
        label: 'Liczby i działania'
    },
    {
        id: '124',
        name: 'log',
        label: 'Logarytmy'
    },
    {
        id: '125',
        name: 'praw',
        label: 'Rachunek prawdopodobieństwa'
    },
    {
        id: '127',
        name: 'rownrow',
        label: 'Równania i nierówności'
    },
    {
        id: '128',
        name: 'stat',
        label: 'Statystyka'
    },
    {
        id: '129',
        name: 'tryg',
        label: 'Trygonometria'
    },
    {
        id: '130',
        name: 'ukrow',
        label: 'Układy równań'
    },
    {
        id: '131',
        name: 'wiel',
        label: 'Wielomiany'
    }
];
const unitsForAdmin = [
    {
        id: '0',
        name:undefined,
        label: '--Brak--'
    },
    {
        id: '111',
        name:'ciag',
        label: 'Ciagi liczbowe'
    },
    {
        id: '112',
        name:'fkw',
        label: 'Funkcja kwadratowa'
    },
    {
        id: '113',
        name:'flin',
        label: 'Funkcja liniowa'
    },
    /* {
         id: '114',
         name:'flog',
         label: 'Funkcja logarytmiczna'
     },*/
    {
        id: '115',
        name:'fwyk',
        label: 'Funkcja wykładnicza'
    },
    {
        id: '116',
        name: 'fwym',
        label: 'Funkcja wymierna'
    },
    {
        id: '117',
        name: 'func',
        label: 'Funkcje'
    },
    {
        id: '118',
        name: 'geoan',
        label: 'Geometria analityczna'
    },
    {
        id: '119',
        name: 'geoprz',
        label: 'Geometria przestrzenna'
    },
    {
        id: '120',
        name: 'geop1',
        label: 'Geometria płaska - pojecia wstępne'
    },
    {
        id: '121',
        name: 'geop',
        label: 'Geometria płaska'
    },
    {
        id: '122',
        name: 'komb',
        label: 'Kombinatoryka'
    },
    {
        id: '123',
        name: 'liczidzia',
        label: 'Liczby i działania'
    },
    {
        id: '124',
        name: 'log',
        label: 'Logarytmy'
    },
    {
        id: '125',
        name: 'praw',
        label: 'Rachunek prawdopodobieństwa'
    },
    {
        id: '126',
        name: 'rroz',
        label: 'Rachunek różniczkowy (Rozszerzenie)'
    },
    {
        id: '127',
        name: 'rownrow',
        label: 'Równania i nierówności'
    },
    {
        id: '128',
        name: 'stat',
        label: 'Statystyka'
    },
    {
        id: '129',
        name: 'tryg',
        label: 'Trygonometria'
    },
    {
        id: '130',
        name: 'ukrow',
        label: 'Układy równań'
    },
    {
        id: '131',
        name: 'wiel',
        label: 'Wielomiany'
    }
];
 const years = [
     {
         name: '24',
         code: '2024'
     },
     {
         name: '23',
         code: '2023'
     },
     {
         name: '22',
         code: '2022'
     },
     {
         name: '21',
         code: '2021'
     },
     {
         name: '20',
         code: '2020'
     },
     {
         name: '19',
         code:'2019'
     },
     {
         name: '18',
         code: '2018'
     },
     {
         name: '17',
         code: '2017'
     },
     {
         name: '16',
         code: '2016'
     },
     {
         name: '15',
         code: '2015'
     },
     {
         name: '14',
         code: '2014'
     },
     {
         name: '13',
         code: '2013'
     },
     {
         name: '12',
         code: '2012'
     },
     {
         name: '11',
         code: '2011'
     },
     {
         name: '10',
         code: '2010'
     },
 ];

const intervalMap = new Map([
    ['week', 'tydzień'],
    ['month', 'miesiąc']
]);

const plans = [];
    /*{
        id: 1,
        title: 'Dostęp na 7 dni',
        value: '7',
        mode: 'subscription',
        subtitle: '9,90 zł/tydzień',
        price: '9,90',
        interval: 'tydzień'
    },
    {
        id: 2,
        title: 'Dostęp na 30 dni',
        value: '30',
        mode: 'payment',
        subtitle: '26,90 zł/miesiąc',
        price: '26,90',
        interval: 'miesiąc',
        isMostPopular: false
    },
    {
        id: 3 ,
        title: 'Dostęp na 90 dni',
        value: '90',
        mode: 'payment',
        subtitle: '19,00 zł/miesiąc',
        price: '19,00',
        interval: 'miesiąc',
        isMostPopular: true
    },
    {
        id: 4,
        title: 'Dostęp na 180 dni',
        value: '180',
        mode: 'payment',
        subtitle: '16,00 zł/miesiąc',
        price: '16,00',
        interval: 'miesiąc',
        isMostPopular: false

    }
]*/

const freePlan = {
    id: 0,
    title: 'Darmowy dostęp',
    value: 15,
    subtitle: '0 zł',
    price: '0',
    interval: ''
}

const labelDetails =  new Map([
    ['03', 'matura próbna'],
    ['05', 'matura'],
    ['06', 'matura dodatkowa'],
    ['08', 'matura poprawkowa'],
    ['09', 'matura próbna'],
    ['11', 'matura próbna'],
    ['12', 'matura próbna'],
]);

const months =  [
    {
        name: '03',
        code: 'marzec'
    },
    {
        name: '05',
        code: 'maj'
    },
    {
        name: '06',
        code: 'czerwiec'
    },
    {
        name: '08',
        code: 'sierpień'
    },
    {
        name: '09',
        code: 'wrzesień'
    },
    {
        name: '12',
        code: 'grudzień'
    }
];
const tabs = [
 /*   {
        id: 0,
        name: 'sub',
        label: 'Zarządzaj subskrypcją'
    },
    {
        id: 1,
        name: 'address',
        label: 'Twoje dane adresowe'
    },*/
    {
        id: 0,
        name: 'achievements',
        label: 'Osiągnięcia',
        icon: 'pi pi-star-fill',
        command: () => {
            this.$router.push('/osiagniecia');
        }
    },
    {
        id: 1,
        name: 'favoriteTasks',
        label: 'Polubione zadania',
        icon: 'pi pi-heart-fill',
        command: () => {
            this.$router.push('/ulubione-zadania');
        }
    },

];
const adminTabs = [
    {
        id: 0,
        name: 'addTask',
        label: 'Dodaj zadanie'
    },
    {
        id: 1,
        name: 'tasks',
        label: 'Lista zadań'
    },
    {
        id: 2,
        name: 'taskPreview',
        label: 'Podgląd zadania'
    },
    {
        id: 3,
        name: 'tableTasks',
        label: 'Tabela zadań'
    },
  /*  {
        id: 4,
        name: 'addMaterial',
        label: 'Dodaj materiał'
    },
    {
        id: 5,
        name: 'updateMaterial',
        label: 'Edycja materiału'
    },*/
    {
        id: 6,
        name: 'adminSQL',
        label: 'Admin SQL'
    },

];
const columns = [
    {
        id: 0,
        key: 'exam',
        label: 'Matura',
        type: 'text',
        display:true
    },
    {
        id:1,
        key: 'type',
        label: 'Typ matury',
        type: 'text',
        display:true
    },
    {
        id:2,
        key: 'taskNumber',
        label: 'Nr zadania',
        type: 'text',
        display:true
    },
    {
        id:3,
        key: 'unit',
        label: 'Dział',
        type: 'text',
        display:true
    },
    {
        id:4,
        key: 'action',
        label: '',
        type: 'button',
        display:true
    },
];

const profanityList = [
    'kurwa', 'dupa', 'chuj', 'pizda', 'pierdol', 'jebany', 'cwel', 'skurwysyn', 'kurwiarz', 'spierdalaj',
    'zajebisty', 'wyjebane', 'popierdolony', 'pierdolić', 'wyruchać', 'wykurwić', 'zjeb', 'spierdol',
    'jebać', 'jebnięty', 'jebaka', 'jebus', 'kutas', 'fiut', 'spermiarz', 'gówno', 'szmata', 'suka',
    'dziwka', 'burdel', 'cwelu', 'huj', 'chujek', 'pierdolony', 'zajebane', 'zasrane', 'rozjebać',
    'dojebany', 'wpierdol', 'rozpierdol', 'przejebane', 'przejebać', 'przefikane', 'zkurwić',
    'kurwiszon', 'kurwica', 'skurwiel', 'skurwić', 'wyruchany', 'wypierdalaj', 'wykurwiony', 'pierdolisz',
    'pierdolnięty', 'wyjebany', 'napięty jak baranie jaja'

];
module.exports = { units,unitsForAdmin, types, typesForAdmin, years, intervalMap, freePlan, plans, labelDetails, months, tabs,adminTabs, columns, profanityList };