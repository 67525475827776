<script>
import LatexData from "@/components/LatexData.vue";
import Comment from "@/components/Comment.vue";
import CreateComment from "@/components/CreateComment.vue";
import axios from "axios";
import CommentParent from "@/components/CommentParent.vue";
import TaskQuestionView from "@/components/TaskQuestionView.vue";
import Scratchpad from "@/components/Scratchpad.vue";

export default {
  name: "TaskCardView",
  components: {TaskQuestionView, CommentParent, LatexData, Comment, CreateComment, Scratchpad},
  emits:['createComment'],
  props: {
    taskId: String,
    customerId: String,
    username: String,
    showAnswers: {
      type: Boolean,
      default: () => true
    },
  },
  created() {
    this.refreshImages();
  },
  data() {
    return {
      fetchedTask: '',
      qUrl: '',
      sUrl: '',
      aUrl: '',
      imgUrl: '',
      showCard: false,
      showComments: false,
      isMultipleChoice: false,
      isProofTask: false
    }
  },
  methods: {
    async refreshImages() {
      this.showCard = false;
      const response = await axios.get('/tasks/id', {
        params: {
          id: this.taskId
        }
      });
      if(!response.data) {
        return;
      }
      this.fetchedTask = response.data;
      this.qUrl = this.fetchedTask.qUrl;
      this.showCard = !!this.qUrl;
      this.sUrl = this.fetchedTask.sUrl;
      this.aUrl = this.fetchedTask.aUrl;
      this.isProofTask = this.aUrl.includes('Patrz');

      this.imgUrl = this.fetchedTask.imgUrl;
      this.isMultipleChoice = this.fetchedTask.isMultipleChoice === 1;
      this.showCard = true;
      this.$refs.taskQuestionView?.getQuestions();
    },
    handleEquRendered() {
      this.showComments = true;
    },
    handleShowToast(toast){
      this.showToast(toast.severity, toast.summary, toast.detail, toast.life);
    },
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }
  }
}
</script>

<template>
  <template v-if="showCard === true">
    <div class="card-container">
      <div class="title-container">
        <h2 class="q-header">Zadanie {{ fetchedTask.taskNumber }}.</h2>
      </div>
      <div class="task-image">
        <img :src="qUrl" :alt="`Zadanie: ${fetchedTask.id}`" class="image-q"/>
      </div>
      <div class="label">
        <p v-if="fetchedTask.isFromExam === 1" class="task-label">{{ fetchedTask.label }}</p>
      </div>
<!--      <Scratchpad></Scratchpad>-->
      <TaskQuestionView v-if="showAnswers"
                        ref="taskQuestionView"
                        :task-id="taskId"
                        :is-multiple-choice="isMultipleChoice"
                        :customer-id="customerId"
                        :is-proof-task="isProofTask"
                        @show-toast="handleShowToast">
      </TaskQuestionView>
      <div v-if="showAnswers === false" class="task-image">
        <h2 class="header">Odp:</h2>
        <LatexData ref="aUrlLatex" :equ="aUrl" class="latex-a"></LatexData>
      </div>
      <div v-if="fetchedTask.showSolutionImg === 1" class="task-image">
        <h2 class="header">Rysunek pomocniczy:</h2>
        <img :src="imgUrl" :alt="`Zadanie: ${fetchedTask.id}`" class="image"/>
      </div>
      <div>
        <div class="center-container">
          <h2 class="header">Rozwiązanie:</h2>
        </div>
        <LatexData ref="sUrlLatex" :equ="sUrl" @equ-rendered="handleEquRendered"></LatexData>
      </div>
      <CommentParent v-if="showComments" :task-id="taskId" :customer-id="customerId"></CommentParent>
    </div>
  </template>
</template>

<style scoped>
.card-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.task-image {
  justify-content: center;
  text-align: center;
}

.title-container {
  text-align: center;
}
.image-q {
  max-width: 100%;
}
.image {
  max-width: 600px;
}

.center-container {
  text-align: center;
}

.task-label {
  text-align: center;
  font-size: 0.9em;
  color: #8B5CF6;
  font-style: italic;
  font-weight: 500;
}
.q-header{
  background-color: #d5b8ea;
  padding: 0.2rem 0;
}

@media screen and (max-width: 640px) {
  .card-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }
  .task-image {
    width: 100%;
  }

  .image-q {
    max-width: 100%;
  }
  .header {
    font-size: 1em;
  }

  .image {
    max-width: 300px;
  }

  .task-label {
    font-size: 0.6em;
  }
  .q-header{
    font-size: 1em;
    background-color: #d5b8ea;
    padding:0.1rem 0;
  }
}
</style>