<script>
import Subscriptions from "@/components/Subscriptions.vue";
import {tabs} from "@/assets/utils";

export default {
  name: "UserPlan" ,
  components: {Subscriptions},
  created() {
    this.getToken();
    this.showSpinner = false;
  },
  data() {
    return {
      token: '',
      showSpinner: false,
      tabs: tabs,
      selectedTab: ''
    }
  },
  methods: {
    getToken() {
      this.token = this.getCookie('lz_token');
      if(!this.token) {
        this.$router.push('/');
      }
    },
    handleShowSpinner(value) {
      this.showSpinner = value;
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Twój plan</h2>
      <div v-if="showSpinner" class="custom-spinner">
        <div class="spinner">
          <semipolar-spinner
              :animation-duration="2000"
              :size="80"
              color="#8B5CF6"
          />
        </div>
      </div>
      <Subscriptions ref="subs" :token="token" @show-spinner="handleShowSpinner"></Subscriptions>
    </div>
  </div>

</template>

<style scoped>

</style>