<script>
export default {
  name: "UserAnswersQuery",
  props: {
    records: Array
  },
  methods: {
    formatValue(value) {
      return value === 1 ? 'true' : 'false';
    },
    getSeverity(value) {
      return value === 1 ? 'success' : 'danger';
    }
  }
}
</script>

<template>
  <DataTable :value="records" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
             paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
             paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
             currentPageReportTemplate="{first} - {last} z {totalRecords}">
    <Column field="id" header="Id"></Column>
    <Column field="username" header="Username"></Column>
    <Column field="email" header="Email"></Column>
    <Column field="questionId" header="Question Id"></Column>
    <Column field="selectedAnswers" header="Selected Answers"></Column>
    <Column header="Is Correct?">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.isCorrect)"
            :severity="getSeverity(slotProps.data.isCorrect)" />
      </template>
    </Column>
    <Column field="createdDate" header="Created Date"></Column>
  </DataTable>
</template>

<style scoped>

</style>