<script>
import TaskCardView from "@/components/TaskCardView.vue";

export default {
  name: "TaskPreview",
  components: {TaskCardView},
  props: {
    customerId: String
  },
  data() {
    return {
      taskId: localStorage.getItem('TaskPreview-taskId') || '',
      showTask: false,
      task: null,
      comments: []
    }
  },
  mounted() {
    if(this.taskId) {
      this.showTask = true;
      this.$refs.taskCard.refreshImages();
    }
  },
  methods: {
    onSubmit() {
      this.showTask = true;
      localStorage.setItem('TaskPreview-taskId', this.taskId);
      this.$refs.taskCard.refreshImages();
    }
  }
}
</script>

<template>
  <div class="mt-2">
    <div class="field">
          <span class="p-float-label">
            <InputText id="taskId" v-model="taskId" type="text" class="input"/>
            <label class="label" for="taskId">Nr zadania</label>
          </span>
    </div>
    <div class="field">
      <Button class="button button-border" type="submit" label="Pobierz" icon="pi pi-check" @click="onSubmit"/>
    </div>
  </div>
  <div v-if="showTask">
    <TaskCardView ref="taskCard" :task-id="taskId" :customer-id="customerId"></TaskCardView>
  </div>
</template>

<style scoped>
.input {
  width: 20em;
  font-size: 1rem;
}

.field {
  margin-bottom: 2rem;
}

.button {
  width: 20em;
}

@media screen and (max-width: 640px) {
  .input {
    width: 100%;
    font-size: 1em;
  }

  .label {
    font-size: 1em;
  }

  .button {
    width: 100%;
    font-size: 1em;
  }
}
</style>