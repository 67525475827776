<script>
import LoginModal from "@/components/LoginModal.vue";
import SignupModal from "@/components/SignupModal.vue";

export default {
  name:'MainPage',
  components: {SignupModal, LoginModal},
  data() {
    return {
      showLoginModal: false,
      showSignupModal: false,
      callback: (response) => {
        // This callback will be triggered when the user selects or login to
        // his Google account from the popup
      }
    }
  },
  methods: {
    handleButtonClick(){
      const token = this.getCookie('lz_token');
      if(token) {
        this.$router.push('/losuj-zadanie');
      } else {
        this.handleShowLoginModal();
      }
    },
    handleShowSignupModal() {
      this.showLoginModal = false;
      this.showSignupModal = !this.showSignupModal;
    },
    handleSignupCompleted() {
      this.$forceUpdate();
      this.handleShowSignupModal();
    },
    handleShowLoginModal() {
      this.showLoginModal = !this.showLoginModal;
    },
    handleLoginCompleted() {
      this.$forceUpdate();
      this.handleShowLoginModal();
    },
    showToastMessage(event){
      this.$toast.add({severity: event.severity, summary: event.summary, detail: event.detail, group:'tc', life: event.life});
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  },

}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body card-center">
          <div>
            <h1 class="header">Losuj Zadanie</h1>
            <h2 class="subheader">Zadania maturalne z matematyki</h2>
            <p class="desc">Szukasz powtórki do matury lub sprawdzianu?</p>
            <p class="desc">600+ zadań z poziomu podstawowego i 200+ z poziomu rozszerzonego.</p>
            <p class="desc">Wybierz poziom zadania oraz dział i losuj zadanie maturalne.</p>
            <Button class="mt-2 button button-border" label="Zaczynamy" @click="handleButtonClick"></Button>
          </div>
    </div>
  </div>
  <Dialog v-model:visible="showSignupModal" modal header="Rejestracja" :style="{ width: '25rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <SignupModal @show-modal="handleShowSignupModal" @register-completed="handleSignupCompleted" @show-toast="showToastMessage"></SignupModal>
  </Dialog>
  <Dialog v-model:visible="showLoginModal" modal header="Logowanie" :style="{ width: '25rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <LoginModal @show-modal="handleShowLoginModal" @login-completed="handleLoginCompleted" @show-toast="showToastMessage" @show-signup="handleShowSignupModal"></LoginModal>
  </Dialog>
</template>

<style scoped>
.card-center {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.desc {
  font-size: 1.25em;
}
.header {
  font-size: 4rem;
  font-family: 'Cambria',serif;
}
.subheader {
  font-size: 2rem;
  font-family: 'Cambria',serif;
}
.desc {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: 'Cambria',serif;
}
.button {
  width: 30em;
  font-size: 1.2rem;
}
@media screen and (max-width: 640px) {
  .header {
    font-size: 2.5rem;
  }
  .subheader {
    font-size: 1.5rem;
    font-family: 'Cambria',serif;
  }
  .desc {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  .button {
    width:100%;
    font-size: 1em;
  }
}
</style>