<script>
import axios from "axios";
import {years, typesForAdmin, unitsForAdmin, months} from '@/assets/utils.js';
import Task from "@/components/Task.vue";

export default {
  name: 'Table',
  components: {Task},
  data() {
    return {
      showModal: false,
      selectedRecord: {},
      showTable: false,
      allRecords: [],
      records: [],
      year: '',
      years: years,
      month: '',
      months: months,
      types: typesForAdmin,
      type: '',
      units: unitsForAdmin,
      unit: '',
      nextIndex: 0,
      previousIndex: 0,
      previousButtonDisabled: false,
      nextButtonDisabled: false
    }
  },
  watch: {
    nextIndex() {
      this.nextButtonDisabled = this.nextIndex > this.records.length -1;
    },
    previousIndex() {
      this.previousButtonDisabled = this.previousIndex === -1;
    }
  },
  methods: {
    handleRecordSelect(event) {
      this.showModal = !this.showModal;
      this.nextIndex = event.index+1;
      this.previousIndex = event.index-1;
      this.selectedRecord = event.data;
    },
    async handleExamChange(event) {
      this.showTable = false;
      let queryFilter = event.value.name;
      const response = await axios.get('/tasks/year', {
        params: {
          year: queryFilter
        }
      });
      this.allRecords = response.data;
      this.filterRecords();
      if (this.records) {
        this.showTable = true;
      }
    },
    handleMonthChange() {
      this.filterRecords();
    },
    handleTypeChange() {
      this.filterRecords();
    },
    handleUnitChange() {
      this.filterRecords();
    },
    filterRecords() {
      const tempRecords = [...this.allRecords];
      this.records = tempRecords.filter(record => {
        const filterByMonth = this.month.name !== undefined ? record.month === this.month.name : true;
        const filterByUnit = this.unit.name !== undefined ? record.unit === this.unit.name : true;
        const filterByType = this.type.name !== undefined ? record.type === this.type.name : true;

        return filterByUnit && filterByType && filterByMonth;
      });
    },
    handleNextTask() {
      this.previousIndex = this.nextIndex-1;
      this.selectedRecord = this.records[this.nextIndex];
      this.nextIndex += 1;

    },
    handlePreviousTask(){
      this.nextIndex = this.previousIndex+1;
      this.selectedRecord = this.records[this.previousIndex];
      this.previousIndex -= 1;
    }
  }
}
</script>

<template>
  <div class="table-body">
    <div class="mb-4 mt-2">
      <Dropdown v-model="year" :options="years" optionLabel="code" placeholder="Rok" @change="handleExamChange"
                class="input"/>
      <Dropdown v-model="month" :options="months" optionLabel="code" placeholder="Miesiac" @change="handleMonthChange"
                class="input"/>
      <Dropdown v-model="type" :options="types" optionLabel="label" placeholder="Typ" @change="handleTypeChange"
                class="input"/>
      <Dropdown v-model="unit" :options="units" optionLabel="label" placeholder="Dział" @change="handleUnitChange"
                class="input"/>
    </div>
    <template v-if="showTable">
      <DataTable :value="records" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
                 @row-click="handleRecordSelect">
        <Column field="id" header="Id"></Column>
        <Column field="unit" header="Unit"></Column>
        <Column field="type" header="Type"></Column>
        <Column field="month" header="Month"></Column>
        <Column field="year" header="Year"></Column>
        <Column field="taskNumber" header="Task no"></Column>
        <Column field="showSolutionImg" header="Show solution img"></Column>
        <Column field="aUrl" style="max-width: 200px" header="A"></Column>
        <Column field="sUrl" style="max-width: 300px" header="S"></Column>
      </DataTable>
    </template>
    <Dialog v-model:visible="showModal" modal header="Zadanie" :style="{ width: '80rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <Task :task="selectedRecord"></Task>
      <template #footer>
        <Button class="button-border navigation-button" label="Poprzednie zadanie" :disabled="previousButtonDisabled" severity="success" icon="pi pi-arrow-circle-left" @click="handlePreviousTask" autofocus/>
        <Button class="button-border navigation-button" label="Nastepne zadanie" :disabled="nextButtonDisabled" severity="success" icon="pi pi-arrow-circle-right" @click="handleNextTask" autofocus/>
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.table-body {
  width: 100%;
}
.input {
  width: 15em;
  margin-right: 2rem;
}

@media screen and (max-width: 640px) {
  .input {
    margin-bottom: 0.5rem;
    width: 90%;
  }
  .navigation-button{
    width: 100%;
    font-size: 0.8rem;
    padding: 0.5rem;
  }
}
</style>