<script>
import axios from "axios";

export default {
  name: "Ranking",
  data() {
    return {
      records: [],
      monthName: '',
      first: 0
    }
  },
  mounted() {
    this.getUser();
    this.getMonth();
  },
  methods: {
    getMonth() {
      const date = new Date();
      this.monthName = date.toLocaleString('pl-PL', { month: 'long' });
    },
    async getStudentRanking() {
      const response = await axios.get('/tasks/getRanking');
      this.records = response.data.currentMonthRanking;
      console.table(this.records);
    },
    async getUser() {
      const token = this.getCookie('lz_token');
      if (token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: token
          }
        });
        this.user = response.data;
      }
      await this.getStudentRanking();
    },
    getCookie(name) {
      return this.$cookies.get(name);
    },
    rowStyle(data) {
      if (this.isCurrentUser(data.username)) {
        return {backgroundColor: '#cbb6fb', fontWeight: 'bold', fontStyle: 'italic'};
      }
    },
    isCurrentUser(username) {
      return this.user && this.user.username === username;
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Ranking miesiąca {{monthName}}</h2>
      <DataTable :value="records"
                 tableStyle="width: 100%"
                 columnResizeMode="fit"
                 size="small"
                 paginator
                 :rows="25"
                  striped-rows
                 v-model:first="first"
                 :rowStyle="rowStyle">
        <Column header="#" headerStyle="width:3rem">
          <template #body="slotProps">
            {{ first + slotProps.index + 1 }}
          </template>
        </Column>
        <Column field="username" header="Nazwa użytkownika">
          <template #body="{ data, index }">
            <div class="flex items-center gap-2">
             <i v-if="first + index === 0" class="pi pi-crown" style="color: gold"></i>
             <i v-if="first + index === 1" class="pi pi-crown" style="color: silver"></i>
             <i v-if="first + index === 2" class="pi pi-crown" style="color: #cd7f32"></i>
              <span class="username">
              {{ data.username }}
                <i v-if="isCurrentUser(data.username)" class="pi pi-user"></i>
              </span>
            </div>
          </template>
        </Column>
        <Column field="totalPoints" header="Punkty"></Column>
      </DataTable>
    </div>
  </div>
</template>

<style scoped>
.pi-crown {
  margin-right: .3rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.pi-user {
  color: #4b5563;
  margin-left: .3rem;
}
.current-user {
  background-color: #cbb6fb;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
}
.current-user-row {
  background-color: #cbb6fb;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
}
.username {
  font-size: 1rem;
}
</style>