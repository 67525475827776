<script>
import ProgressBar from "@/components/ProgressBar.vue";
import axios from "axios";

export default {
  name: "AchievementCard" ,
  components: {ProgressBar},
  props: {
    achievement: Object,
    customerId: String
  },
  created() {
    this.getAchievement();
  },
  data(){
    return {
      progress: 50,
      showProgressBar: false,
      buttonStyle: '',
      user: '',
      achievementUnlockedDate: '',
      achievementUnlockedTime: ''
    }
  },
  methods: {
    async getAchievement() {
      this.buttonStyle = 'color: #cbcccd;'
      if(this.achievement.isInProgress) {
        this.progress = this.achievement.progress;
      } else if (this.achievement.isDone) {
        const date = this.achievement.completionDate;
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        this.achievementUnlockedDate = date.toLocaleDateString('pl-PL', options);
        this.achievementUnlockedTime = date.toLocaleTimeString('pl');
        this.buttonStyle = 'color: #8B5CF6; opacity: 1;';
      }
    },
  }
}
</script>

<template>
  <div class="grid">
    <div class="col-4 badge-container">
      <Button class="badge" :style="buttonStyle" :label="achievement.initials" disabled="true">
      </Button>
    </div>
    <div class="col-8 text-left">
      <h3 class="achievement-label">{{achievement.label}}</h3>
      <div v-if="achievement.isDone" >
        <h3 class="achievement-unlocked">Gratulacje! Ukończono poziom! 🏅</h3>
        <p class="text-left achievement-unlocked-date">Ukończono {{achievementUnlockedDate}} o {{achievementUnlockedTime}}</p>
      </div>
      <div class="mt-2 progress-bar-container">
        <ProgressBar v-if="achievement.isInProgress" :value="progress"></ProgressBar>
      </div>
      <div class="percentage-of-users-container">
        <p class="no-margin">{{achievement.percentageOfUsers}}% uczniów odblokowało to osiągnięcie</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.badge-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 10px solid;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: white;
  font-size: 1.8rem;
  transition: all .2s ease-in-out;
  font-style: italic;
}
.progress-bar-container {
  font-size: 1rem !important;
}
.achievement-label {
  font-weight: bold;
}
.achievement-unlocked {
  font-weight: bold;
  color: #8B5CF6;
  margin: 0;
}
.percentage-of-users-container {
  font-size: 0.8rem;
  text-align: left;
}
.achievement-unlocked-date{
  margin-bottom: 0;
}
.no-margin {
  margin-bottom: 0;
  margin-top: 0.5rem;
}
@media screen and (max-width: 640px) {
  .badge {
    width: 100px;
    height: 100px;
    font-size: 1.2rem;
  }
}
</style>