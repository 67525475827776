<script>
import VueDrawingCanvas from "vue-drawing-canvas";
export default {
  name: "Scratchpad",
  components: {
    VueDrawingCanvas,
  },
  data() {
    return {
      image: '',
      grid: { x: 16, y: 16 },
    }
  },
  mounted() {
    this.drawGrid2();
  },
  methods: {
    drawGrid() {
      const canvas = this.$refs.canvas.$el;
      const ctx = canvas.getContext('2d');
      const gridSize = 20; // Wielkość jednej kratki (np. 20px)

      // Rysowanie kratki
      ctx.beginPath();
      ctx.strokeStyle = "#ccc"; // Kolor kratki
      ctx.lineWidth = 1;

      for (let x = 0; x <= canvas.width; x += gridSize) {
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.height);
      }

      for (let y = 0; y <= canvas.height; y += gridSize) {
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y);
      }

      ctx.stroke();
      ctx.closePath();
    },
    drawGrid2() {
      const canvas = this.$refs.canvas.$el;
      const context = canvas.getContext('2d');
      if (!context) {
        console.error('Context not available!');
        return;
      }

      const w = canvas.width - 2; // Szerokość z uwzględnieniem marginesu
      const h = canvas.height - 2; // Wysokość z uwzględnieniem marginesu

      // Rysowanie pionowych linii kratki
      for (let x = 1; x < canvas.width; x += w / this.grid.x) {
        context.moveTo(x, 0);
        context.lineTo(x, canvas.height);
      }

      // Rysowanie poziomych linii kratki
      for (let y = 1; y < canvas.height; y += h / this.grid.y) {
        context.moveTo(0, y);
        context.lineTo(canvas.width, y);
      }

      context.stroke(); // Zastosowanie rysunku
    },
    clearCanvas(){
      this.$refs.canvas.reset();
    }
  }
}
</script>

<template>
  <div>
    <h2>Brudnopis</h2>
    <VueDrawingCanvas
        ref="canvas"
        :width="940"
        :height="500"
        saveAs="png"
        :styles="{
            border: 'solid 1px #4b5563',
          }"
        :lineColor="'#4b5563'"/>
    <Button @click="clearCanvas">Wyczyść</Button>
  </div>

</template>

<style scoped>

</style>