<script>
import UserData from "@/components/UserData.vue";
import FavoriteTasks from "@/components/FavoriteTasks.vue";
import Subscriptions from "@/components/Subscriptions.vue";
import {SemipolarSpinner} from "epic-spinners";
import {tabs} from '@/assets/utils.js';
import Achievements from "@/components/Achievements.vue";
import TaskList from "@/components/TaskList.vue";
import axios from "axios";

export default {
  name: "Profile",
  components: {Achievements, UserData, Subscriptions, FavoriteTasks, SemipolarSpinner, TaskList},
  created() {
    this.getToken();
    this.showSpinner = false;
  },
  data() {
    return {
      token: '',
      showSpinner: false,
      tabs: [],
      activeTab: parseInt(localStorage.getItem('activeTab')) || 0,
      user: ''
    }
  },
  methods: {
    async getToken() {
      this.token = this.getCookie('lz_token');
      let tempTabs = [];
      if(!this.token) {
        this.$router.push('/');
      }
      if (this.token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: this.token
          }
        });
        this.user = response.data;
        if (this.user) {
          tempTabs = [{
            id: 0,
            name: 'achievements',
            label: 'Osiągnięcia',
            icon: 'pi pi-star-fill',
            component: Achievements,
            props: {}
          },
            {
              id: 1,
              name: 'favoriteTasks',
              label: 'Polubione zadania',
              icon: 'pi pi-heart-fill',
              component: FavoriteTasks,
              props: {}
            }];
          if ((this.user.type === 'teacher' && this.user.subExpirationDate)
              || this.user.type === 'admin') {
            let taskList = {
              id: 2,
              name: 'taskList',
              label: 'Historia wyszukiwań',
              icon: 'pi pi-search',
              component: TaskList,
              props: {}
            }
            tempTabs = [...tempTabs, taskList];
          }
        }
      }
      this.updateTabsWithToken(tempTabs);
    },
    updateTabsWithToken(tabs) {
      this.tabs = tabs.map((tab) => {
        return {
          ...tab,
          props: {...tab.props, token: this.token, customerId: this.user.customerId},
        };
      });
    },
    handleShowSpinner(value) {
      this.showSpinner = value;
    },
    onTabChange(event) {
      this.activeTab = event.index;
      localStorage.setItem('activeTab', event.index);
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Profil</h2>
      <div v-if="showSpinner" class="custom-spinner">
        <div class="spinner">
          <semipolar-spinner
              :animation-duration="2000"
              :size="80"
              color="#8B5CF6"
          />
        </div>
      </div>
      <TabView v-if="tabs.length > 0" :model="tabs" :scrollable="true" v-model:activeIndex="activeTab" @tab-change="onTabChange">
        <TabPanel
            v-for="tab in tabs"
            :key="tab.id">
          <template #header>
            <i :class="tab.icon" style="margin-right: 0.5rem;"></i>
            {{ tab.label }}
          </template>
          <component :is="tab.component" v-bind="tab.props"></component>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<style scoped>
.card-panel {
  margin-top: 1rem;
  justify-content: center;
  text-align: left;
  width: 100%;
}
.card-body {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: 80em;
}
.tab-view {
  display: block;
}
.mobile-view {
  display:none;
}
.input {
  width: 100%;
}
.mobile-container {
  margin-top: 2rem;
}
.component-container {
   margin-top: 3rem;
}
.custom-spinner {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
}
@media screen and (max-width: 640px) {
  .card-panel {
    width: 100%;
  }
  .card-body {
    width: 100%;
    padding: 1rem;
  }
  .tab-view {
    display: none;
  }
  .mobile-view {
    display:block;
  }
}
</style>