<script>
export default {
  name: 'PlanCard',
  emits: ['plan-selected'],
  props: {
    plan: Object,
    disablePlanSelection: Boolean
  },
  methods: {
    handlePlanSelected(){
      this.$emit('plan-selected', this.plan);
    }
  }
}
</script>

<template>
  <div class="plan-container">
    <div class="plan-content">
      <h1 class="price"> <span class="old-price">{{plan.price}} zł</span> </h1>
      <h3 class="plan-title">{{plan.title}}</h3>
      <div class="bonus-container grey">
        <span class="pi pi-unlock bonus-icon"></span>
        <span class="price-text">Dostęp do <strong>{{plan.value}} losowych zadań</strong></span>
      </div>
      <Button class="button" label="Wybieram" icon="pi pi-check" @click="handlePlanSelected" :disabled="disablePlanSelection"></Button>
    </div>
  </div>
</template>

<style scoped>
.button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 94%;
}
.price {
  color: #8B5CF6;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 1rem;
  font-size: 4rem;
}
.plan-container {
  border: 2px solid #8B5CF6;
  border-radius: 15px;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.bonus-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #4b5563;
  padding: 1rem;

}

.bonus-icon {
  flex-shrink: 0;
  font-size: 1.5rem;
  color: #8B5CF6;
  width: 2rem;
  text-align: left;
}

.price-text {
  text-align:left;
}
.grey {
  background-color: #ececec;
}
@media screen and (max-width: 640px) {
  .button {
    font-size: 1rem;
  }
}

</style>