<script>
import axios from "axios";
import AchievementCard from "@/components/AchievementCard.vue";

export default {
  name: "Achievements",
  components: {AchievementCard},
  props: {
    token: String
  },
  created() {
    this.checkUser();
  },

  data() {
    return {
      customerId: '',
      availableAchievements: [],
      filteredAchievements: [],
      showMessage: false
    }
  },
  methods: {
    async checkUser() {
      if (this.token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: this.token
          }
        });
        const user = response.data;
        if(user.isPremiumUser === 0 || (user.isPremiumUser === 1 && user.freePlanActivated === 1)){
          await this.getAchievementForNonPremiumUser(this.customerId);
        } else {
          this.customerId = user.customerId;
          if(this.customerId) {
            await this.getAchievements(this.customerId);
          }
        }
      }
    },
    async getAchievements(customerId){
       await axios.post('/achievements/createUserAchievements', {
        customerId: customerId,
      });
      const achievements = await axios.get('/achievements/get');
      this.availableAchievements = achievements.data;
      const userAchievements =  await axios.get('/achievements/getUserAchievements', {
        params: {
          customerId: customerId,
        }
      });
      this.filteredAchievements = this.availableAchievements.map((achievement) => {
        const userAchievement = userAchievements.data.find(
            (response) => response.achievementId === achievement.id
        );
        return {
          ...achievement,
          totalTasks: userAchievement?.totalTasks,
          progress: Math.round((userAchievement?.progress / userAchievement?.totalTasks * 100) || 0),
          isInProgress: userAchievement?.isDone === 0,
          isDone : userAchievement?.isDone === 1,
          completionDate: new Date(userAchievement?.completionDate),
          percentageOfUser: userAchievement?.percentageOfUser,
        }
      }).sort((a, b) => {
            const aStarted = a.progress > 0 ? 1 : 0;
            const bStarted = b.progress > 0 ? 1 : 0;

            if (bStarted !== aStarted) {
              return bStarted - aStarted;
            }
            return b.progress - a.progress;
      });
    },
    async getAchievementForNonPremiumUser(){
      const achievements = await axios.get('/achievements/get');
      this.filteredAchievements = achievements.data;
    }
  }
}
</script>

<template>
    <div class="grid grid-parent text-center">
      <div v-for="achievement in filteredAchievements" class="col-12 md:col-6 achievement-container">
        <AchievementCard
            :achievement="achievement"
            :customer-id="customerId">
        </AchievementCard>
      </div>
    </div>
</template>

<style scoped>
.grid-parent {
  gap: 1rem;
}
.achievement-container {
  width:49%;
  padding:1rem;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 640px) {
  .achievement-container {
    width: 100%;
    margin-bottom: 2em;
  }
}
</style>