<script>
export default {
  name: "LoginsQuery",
  props: {
    records: Array
  },
  methods: {
    getSeverity(value) {
      return value === 0 ? 'danger' : 'success';
    }
  }
}
</script>

<template>
  <DataTable :value="records" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
             paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
             paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
             currentPageReportTemplate="{first} - {last} z {totalRecords}">
    <Column field="id" header="Id"></Column>
    <Column field="username" header="Username"></Column>
    <Column field="email" header="Email"></Column>
    <Column field="createdDate" header="Created Date"></Column>
    <Column field="expiredDate" header="Expired Date"></Column>
    <Column header="Is Used">
      <template #body="slotProps">
        <Tag
            :value="slotProps.data.isUsed || '0'"
            :severity="getSeverity(slotProps.data.isUsed)" />
      </template>
    </Column>
    <Column field="device" header="Device"></Column>

  </DataTable>
</template>

<style scoped>

</style>