<script>
import axios from 'axios';
import PlanCard from "@/components/PlanCard.vue";
export default {
  name: "Plans",
  components: {PlanCard},
  emits: ['plan-selected'],
  props: {
    hideButtons: Boolean
  },
  created(){
    this.getPlans();
  },
  data() {
    return {
      plans: [],
      discount: '',
      expiredDate: null,
      promoMessage: '',
      timerInterval: null
    }
  },
  mounted() {
    this.timerInterval = setInterval(this.updateTimer, 1000);
  },
  methods: {
    async getPlans() {
      const response = await axios.get('/offers/get');
      this.plans = response.data.plans;
      if(response.data.discount.length < 0){
        return;
      }
      this.discount = response.data.discount[0];
      this.expiredDate = new Date(this.discount?.expiredDate);
      this.updateTimer();
    },
    updateTimer() {
      if (!this.expiredDate) {
        this.promoMessage = '';
        return;
      }
      const now = new Date();
      const timeLeft = this.expiredDate - now;
      if (timeLeft > 0) {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        this.promoMessage = `${this.discount.label} kończy się za ${days}d ${hours}h ${minutes}m ${seconds}s`;
      } else {
        clearInterval(this.timerInterval);
        this.promoMessage  = '';
      }
    },
    handlePlanSelected(event){
      this.$emit('plan-selected', event);
    }
  }
}
</script>

<template>
  <div v-if="discount" class="discount">
    <h2 class="header">{{promoMessage}}</h2>
  </div>
  <div class="custom-grid">
    <div v-for="plan in plans" class="single-plan">
      <PlanCard :plan="plan" @plan-selected="handlePlanSelected"
                :hide-buttons="hideButtons"></PlanCard>
    </div>
  </div>
</template>

<style scoped>
.discount{
  margin-bottom: 3rem;
  margin-top:0;
}
.custom-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.single-plan {
  width:30%;
  margin-left: auto;
  margin-right: auto;
}
.header{
  color: #22c55e;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .single-plan {
    width: 100%;
    margin-bottom: 2em;
  }
}
</style>