<script>
export default {
  name: "UsersQuery",
  props: {
    records: Array
  },
  methods: {
    formatValue(value) {
      return value === 1 ? 'true' : 'false';
    },
    getSeverity(value) {
      return value === 1 ? 'success' : 'danger';
    }
  }
}
</script>

<template>
  <div class="mb-4">
    <strong>Wszystkich użytkowników: {{this.records.length}}</strong>
  </div>
  <DataTable :value="records" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
             paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
             paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
             currentPageReportTemplate="{first} - {last} z {totalRecords}">
    <Column field="username" header="Username"></Column>
    <Column field="email" header="Email"></Column>
    <Column field="createdDate" header="Created Date"></Column>
    <Column field="type" header="Type"></Column>
    <Column field="customerId" header="Customer Id"></Column>
    <Column header="Is Premium user">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.isPremiumUser)"
            :severity="getSeverity(slotProps.data.isPremiumUser)" />
      </template>
    </Column>
    <Column header="Email Verified">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.isEmailVerified)"
            :severity="getSeverity(slotProps.data.isEmailVerified)" />
      </template>
    </Column>
    <Column field="subExpirationDate" header="Sub Exp Date"></Column>
    <Column header="FP Activated">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.freePlanActivated)"
            :severity="getSeverity(slotProps.data.freePlanActivated)" />
      </template>
    </Column>
    <Column header="FP Expired">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.freePlanExpired)"
            :severity="getSeverity(slotProps.data.freePlanExpired)" />
      </template>
    </Column>
  </DataTable>
</template>

<style scoped>

</style>