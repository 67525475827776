const examYears = [
    {
        id: 24,
        name: 2024,
        monthsPP: [
            {
                id: 5,
                parentId: 24,
                name: 'maj',
                value: '05',
                urlName: 'maj-2024-pp'
            },
            {
                id: 6,
                name: 'czerwiec',
                urlName: 'czerwiec-2024-pp'
            },
            {
                id: 8,
                name: 'sierpień',
                urlName: 'sierpien-2024-pp'
            },
            {
                id: 12,
                name: 'grudzień',
                urlName: 'grudzień-2024-pp'
            }

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2024-pr'
            },
            {
                id: 6,
                name: 'czerwiec',
                urlName: 'czerwiec-2024-pr'
            },
        ]
    },
    {
        id: 23,
        name: 2023,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2023-pp'
            },
            {
                id: 6,
                name: 'czerwiec',
                urlName: 'czerwiec-2023-pp'
            },
            {
                id: 8,
                name: 'sierpień',
                urlName: 'sierpien-2023-pp'
            },
            {
                id: 12,
                name: 'grudzień',
                urlName: 'grudzien-2023-pp'
            }

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2023-pr'
            },
            {
                id: 6,
                name: 'czerwiec',
                urlName: 'czerwiec-2023-pr'
            },
        ]
    },
    {
        id: 22,
        name: 2022,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2022-pp'
            },
            {
                id: 6,
                name: 'czerwiec',
                urlName: 'czerwiec-2022-pp'
            },
            {
                id: 8,
                name: 'sierpień',
                urlName: 'sierpien-2022-pp'
            },
            {
                id: 9,
                name: 'wrzesień',
                urlName: 'wrzesien-2022-pp'
            },
            {
                id: 12,
                name: 'grudzień',
                urlName: 'grudzien-2022-pp'
            }

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2022-pr'
            },
        ]
    },
    {
        id: 21,
        name: 2021,
        monthsPP: [
            {
                id: 3,
                name: 'marzec',
                urlName: 'marzec-2021-pp'
            },
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2021-pp'
            },
            {
                id: 6,
                name: 'czerwiec',
                urlName: 'czerwiec-2021-pp'
            },
            {
                id: 8,
                name: 'sierpień',
                urlName: 'sierpien-2021-pp'
            }

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2021-pr'
            },
        ]
    },
    {
        id: 20,
        name: 2020,
        monthsPP: [
            {
                id: 6,
                name: 'czerwiec',
                urlName: 'czerwiec-2020-pp'
            }

        ],
        monthsPR: [
            {
                id: 6,
                name: 'czerwiec',
                urlName: 'czerwiec-2020-pr'
            },
        ]
    },
    {
        id: 19,
        name: 2019,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2019-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2019-pr'
            },
        ]
    },
    {
        id: 18,
        name: 2018,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2018-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2018-pr'
            },
        ]
    },
    {
        id: 17,
        name: 2017,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2017-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2017-pr'
            },
        ]
    },
    {
        id: 16,
        name: 2016,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2016-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2016-pr'
            },
        ]
    },
    {
        id: 15,
        name: 2015,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2015-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2015-pr'
            },
        ]
    },
    {
        id: 14,
        name: 2014,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2014-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2014-pr'
            },
        ]
    },
    {
        id: 13,
        name: 2013,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2013-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2013-pr'
            },
        ]
    },
    {
        id: 12,
        name: 2012,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2012-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2012-pr'
            },
        ]
    },
    {
        id: 11,
        name: 2011,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2011-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2011-pr'
            },
        ]
    },
    {
        id: 10,
        name: 2010,
        monthsPP: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2010-pp'
            },

        ],
        monthsPR: [
            {
                id: 5,
                name: 'maj',
                urlName: 'maj-2010-pr'
            },
        ]
    },
];

const examNames = [
    {
        id: 2451,
        urlName: 'maj-2024-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2024',
        type: 'p',
        month: '05',
        year: '24'
    },
    {
        id: 246,
        urlName: 'czerwiec-2024-pp',
        header: 'CKE, matura dodatkowa-poziom podstawowy, czerwiec 2024',
        type: 'p',
        month: '06',
        year: '24'
    },
    {
        id: 248,
        urlName: 'sierpien-2024-pp',
        header: 'CKE, matura poprawkowa-poziom podstawowy, sierpień 2024',
        type: 'p',
        month: '08',
        year: '24'
    },
    {
        id: 248,
        urlName: 'grudzień-2024-pp',
        header: 'CKE, matura próbna-poziom podstawowy, grudzień 2024',
        type: 'p',
        month: '12',
        year: '24'
    },
    {
        id: 2452,
        urlName: 'maj-2024-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2024',
        type: 'r',
        month: '05',
        year: '24'
    },
    {
        id: 2461,
        urlName: 'czerwiec-2024-pr',
        header: 'CKE, matura dodatkowa-poziom rozszerzony, czerwiec 2024',
        type: 'r',
        month: '06',
        year: '24'
    },
    {
        id: 2351,
        urlName: 'maj-2023-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2023',
        type: 'p',
        month: '05',
        year: '23'
    },
    {
        id: 2352,
        urlName: 'maj-2023-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2023',
        type: 'r',
        month: '05',
        year: '23'
    },
    {
        id: 2361,
        urlName: 'czerwiec-2023-pr',
        header: 'CKE, matura dodatkowa-poziom rozszerzony, czerwiec 2023',
        type: 'r',
        month: '06',
        year: '23'
    },
    {
        id: 236,
        urlName: 'czerwiec-2023-pp',
        header: 'CKE, matura dodatkowa-poziom podstawowy, czerwiec 2023',
        type: 'p',
        month: '06',
        year: '23'
    },
    {
        id: 238,
        urlName: 'sierpien-2023-pp',
        header: 'CKE, matura poprawkowa-poziom podstawowy, sierpień 2023',
        type: 'p',
        month: '08',
        year: '23'
    },
    {
        id: 2312,
        urlName: 'grudzien-2023-pp',
        header: 'CKE, matura próbna-poziom podstawowy, grudzień 2023',
        type: 'p',
        month: '12',
        year: '23'
    },
    {
        id: 2251,
        urlName: 'maj-2022-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2022',
        type: 'p',
        month: '05',
        year: '22'
    },
    {
        id: 2252,
        urlName: 'maj-2022-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2022',
        type: 'r',
        month: '05',
        year: '22'
    },
    {
        id: 226,
        urlName: 'czerwiec-2022-pp',
        header: 'CKE, matura dodatkowa-poziom podstawowy, czerwiec 2022',
        type: 'p',
        month: '06',
        year: '22'
    },
    {
        id: 228,
        urlName: 'sierpien-2022-pp',
        header: 'CKE, matura poprawkowa-poziom podstawowy, sierpień 2022',
        type: 'p',
        month: '08',
        year: '22'
    },
    {
        id: 229,
        urlName: 'wrzesien-2022-pp',
        header: 'CKE, matura dodatkowa-poziom podstawowy, wrzesień 2022',
        type: 'p',
        month: '09',
        year: '22'
    },
    {
        id: 2212,
        urlName: 'grudzien-2022-pp',
        header: 'CKE, matura próbna-poziom podstawowy, grudzień 2022',
        type: 'p',
        month: '12',
        year: '22'
    },
    {
        id: 213,
        urlName: 'marzec-2021-pp',
        header: 'CKE, matura-poziom podstawowy, marzec 2021',
        type: 'p',
        month: '03',
        year: '21'
    },
    {
        id: 2151,
        urlName: 'maj-2021-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2021',
        type: 'p',
        month: '05',
        year: '21'
    },
    {
        id: 2152,
        urlName: 'maj-2021-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2021',
        type: 'r',
        month: '05',
        year: '21'
    },
    {
        id: 216,
        urlName: 'czerwiec-2021-pp',
        header: 'CKE, matura dodatkowa-poziom podstawowy, czerwiec 2021',
        type: 'p',
        month: '06',
        year: '21'
    },
    {
        id: 218,
        urlName: 'sierpien-2021-pp',
        header: 'CKE, matura poprawkowa-poziom podstawowy, sierpień 2021',
        type: 'p',
        month: '08',
        year: '21'
    },
    {
        id: 2061,
        urlName: 'czerwiec-2020-pp',
        header: 'CKE, matura-poziom podstawowy, czerwiec 2020',
        type: 'p',
        month: '06',
        year: '20'
    },
    {
        id: 2062,
        urlName: 'czerwiec-2020-pr',
        header: 'CKE, matura-poziom rozszerzony, czerwiec 2020',
        type: 'r',
        month: '06',
        year: '20'
    },
    {
        id: 1951,
        urlName: 'maj-2019-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2019',
        type: 'p',
        month: '05',
        year: '19'
    },
    {
        id: 1952,
        urlName: 'maj-2019-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2019',
        type: 'r',
        month: '05',
        year: '19'
    },
    {
        id: 1851,
        urlName: 'maj-2018-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2018',
        type: 'p',
        month: '05',
        year: '18'
    },
    {
        id: 1852,
        urlName: 'maj-2018-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2018',
        type: 'r',
        month: '05',
        year: '18'
    },
    {
        id: 1751,
        urlName: 'maj-2017-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2017',
        type: 'p',
        month: '05',
        year: '17'
    },
    {
        id: 1752,
        urlName: 'maj-2017-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2017',
        type: 'r',
        month: '05',
        year: '17'
    },
    {
        id: 1651,
        urlName: 'maj-2016-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2016',
        type: 'p',
        month: '05',
        year: '16'
    },
    {
        id: 1652,
        urlName: 'maj-2016-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2016',
        type: 'r',
        month: '05',
        year: '16'
    },
    {
        id: 1551,
        urlName: 'maj-2015-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2015',
        type: 'p',
        month: '05',
        year: '15'
    },
    {
        id: 1552,
        urlName: 'maj-2015-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2015',
        type: 'r',
        month: '05',
        year: '15'
    },
    {
        id: 1451,
        urlName: 'maj-2014-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2014',
        type: 'p',
        month: '05',
        year: '14'
    },
    {
        id: 1452,
        urlName: 'maj-2014-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2014',
        type: 'r',
        month: '05',
        year: '14'
    },
    {
        id: 1351,
        urlName: 'maj-2013-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2013',
        type: 'p',
        month: '05',
        year: '13'
    },
    {
        id: 1352,
        urlName: 'maj-2013-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2013',
        type: 'r',
        month: '05',
        year: '13'
    },
    {
        id: 1251,
        urlName: 'maj-2012-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2012',
        type: 'p',
        month: '05',
        year: '12'
    },
    {
        id: 1252,
        urlName: 'maj-2012-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2012',
        type: 'r',
        month: '05',
        year: '12'
    },
    {
        id: 1151,
        urlName: 'maj-2011-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2011',
        type: 'p',
        month: '05',
        year: '11'
    },
    {
        id: 1152,
        urlName: 'maj-2011-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2011',
        type: 'r',
        month: '05',
        year: '11'
    },
    {
        id: 1051,
        urlName: 'maj-2010-pp',
        header: 'CKE, matura-poziom podstawowy, maj 2010',
        type: 'p',
        month: '05',
        year: '10'
    },
    {
        id: 1052,
        urlName: 'maj-2010-pr',
        header: 'CKE, matura-poziom rozszerzony, maj 2010',
        type: 'r',
        month: '05',
        year: '10'
    }
]

module.exports = { examYears, examNames };