<script>
import LatexData from "@/components/LatexData.vue";
import Task from "@/components/Task.vue";
import axios from "axios";

export default {
  name: "UpdateTask",
  components: {LatexData, Task},
  data() {
    return {
      taskId: '',
      showTask: false,
      task: '',
      noTaskMessage: ''
    }
  },
  methods: {
    async getTask(){
      const response = await axios.get('/tasks/id', {
        params: {
          id: this.taskId
        }
      });
     this.task = response.data;
     if(this.task) {
       this.showTask = true;
     } else {
       this.noTaskMessage = 'Brak zadania';
     }

    }
  }
}
</script>

<template>
  <div>
    <div class="field mb-4 mt-4">
      <span class="p-float-label">
        <InputText id="taskId" v-model="taskId" type="text" class="input"/>
        <label for="taskId">Task Id</label>
      </span>
    </div>
    <div>
      <Button class="mb-5" type="submit" label="Pobierz zadanie" icon="pi pi-check" @click="getTask"/>
    </div>
    <div class="mt-5">
      <Task v-if="showTask" :task="task"></Task>
      <div v-else>
        <p>{{noTaskMessage}}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.textarea {
  min-width: 30rem;
  min-height: 20rem;
}
</style>