<script>
import axios from "axios";

export default {
  name: "TaskListQuery",
  props: {
    customerId: String
  },
  created(){
    this.getSearchLogs();
  },
  data() {
    return {
      searchLogs: [],
      records: [],
      searchLog:  null,
      searchLogName: ''
    }
  },
  mounted() {
    const savedSearchLog = localStorage.getItem('searchLog');
    if (savedSearchLog) {
      this.searchLog = JSON.parse(savedSearchLog);
      this.searchLogName = this.searchLog.name;
      if(this.searchLog) {
        this.handleSearchLogSelect(this.searchLog);
      }
    }
  },
  methods: {
    async getSearchLogs() {
      const storedCustomerId = localStorage.getItem('customerId');
      const response = await axios.get('/logs/getSearchLogsByCustomerId', {
        params: {
          customerId: storedCustomerId ? storedCustomerId : this.customerId
        }
      });
      this.searchLogs = response.data;

    },
    async handleSearchLogSelect(event) {
      this.records = [];
      if (event.value) {
        localStorage.setItem('searchLog', JSON.stringify(event.value));
        this.searchLogName = event.value.name;
      }
      const response = await axios.get('/tasks/getSearchTasks', {
        params: {
          searchLogId: this.searchLog.id
        }
      });
      this.records = response.data;
    },
    async saveName() {
      const response = await axios.post('/logs/updateSearchLog', {
        id: this.searchLog.id,
        name: this.searchLogName
      });
      if (response.status === 200) {
        this.$toast.add({severity: 'success', summary: 'Sukcess', detail: 'nazwa zapisana', group: 'tc', life: '2000'});
        this.searchLog.name = this.searchLogName;
        localStorage.setItem('searchLog', JSON.stringify(this.searchLog));
      } else {
        this.$toast.add({severity: 'error', summary: 'Error', detail: response, group: 'tc', life: 2000});
      }
    },
    handleRecordSelect(event) {
      this.$router.push({
        name: 'zadanie',
        params: {
          name: event.taskId
        }
      });
    },
   async handleEdit(value) {
      const response = await axios.post('/tasks/updateSearchTask', {
        id: value.id,
      });
     if (response.data) {
       this.$toast.add({severity: 'success', summary: 'Sukcess', detail: 'zadanie ukończone', group: 'tc', life: 2000});
       await this.handleSearchLogSelect(value);
     } else {
       this.$toast.add({severity: 'error', summary: 'Error', detail: response, group: 'tc', life: 2000});
     }
    },
    formatValue(value) {
      return value === 1 ? 'tak' : 'nie';
    },
    getSeverity(value) {
      return value === 1 ? 'success' : 'danger';
    }
  }
}
</script>

<template>
  <div>
    <div class="field">
          <span class="p-float-label">
            <Dropdown v-model="searchLog" :options="searchLogs" optionLabel="name" placeholder="Wyszukiwania" class="input" @change="handleSearchLogSelect"/>
            <label for="searchLog">Twoje wyszukiwania</label>
          </span>
    </div>
    <div  v-if="searchLog" class="field">
          <span class="p-float-label">
            <InputText id="searchLogName" v-model="searchLogName" type="text" class="input"/>
            <label for="searchLogName">Nazwa wyszukania</label>
          </span>
    </div>
    <Button class="mb-5 save-button button-border" label="Zapisz nazwę" icon="pi pi-check" @click="saveName"/>
    <DataTable v-if="records.length > 0" :value="records" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
               paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
               paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
               currentPageReportTemplate="{first} - {last} z {totalRecords}">
      <Column field="id" header="Id"></Column>
      <Column field="taskId" header="Id zadania"></Column>
      <Column header="Zakończone?">
        <template #body="slotProps">
          <Tag
              :value="formatValue(slotProps.data.isDone)"
              :severity="getSeverity(slotProps.data.isDone)" />
        </template>
      </Column>
      <Column  field="createdDate" header="Data utworzenia"></Column>
      <Column  field="completedDate" header="Data ukończenia"></Column>
      <Column header="Akcja">
        <template #body="slotProps">
          <Button
              label="Otwórz"
              class="p-button-sm p-button-rounded"
              severity="success"
              @click="handleRecordSelect(slotProps.data)"
          />
          <Button
              label="Zakończ"
              icon="pi pi-pencil"
              :disabled="slotProps.data.isDone"
              class="p-button-sm p-button-rounded ml-3"
              @click="handleEdit(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

</template>

<style scoped>
.field {
  margin-top: 2rem;
}
.input {
  width: 40rem;
}
@media screen and (max-width: 640px) {
.input {
  width: 20rem;
}
}
</style>