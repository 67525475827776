<script>
import axios from "axios";
import Dialog from "primevue/dialog";
import LoginModal from "@/components/LoginModal.vue";

export default {
  name: "ConfirmEmail",
  components: {LoginModal, Dialog},
  created() {
    this.validToken();
  },
  methods: {
    async validToken() {
      const url = this.$route.query;
      const response = await axios.post('/users/validEmailConfirmationToken', {
        email: url.email,
        token: url.token
      });
      if (response.data.status === 200) {
        this.showToastMessage('success', 'Twoje konto zostało potwierdzone', '', 2000);
        this.showLoginModal = !this.showLoginModal;
        this.$router.push('/');
      } else {
        this.showToastMessage('error', 'Błąd',response.data.message, 2000);
      }
    },
    showToastMessage(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }
  }
}
</script>

<template>
</template>

<style scoped>

</style>