<script>
import LatexData from "@/components/LatexData.vue";
import axios from "axios";

export default {
  name: "UpdateMaterial",
  components: {LatexData},
  data() {
    return {
      text: '',
      rawText: '',
      subunitId: '',
      subCategory: '',
      name: '',
      category: '',
      title: ''
    }
  },
  methods: {
    renderText(event) {
      this.text = event;
      this.$refs.textLatex.processEqu(event);
    },
    async onSubmit() {
      const response = await axios.post('/subunit/insert', {
        category: this.category,
        subCategory: this.subCategory,
        text: this.rawText,
        title: this.title,
        name: this.name
      });
      if (response.status === 200) {
        this.$toast.add({severity: 'success', sumary: 'Sukces', detail: 'Sukces', group: 'tc', life: 2000});
        this.resetForm();
      }
    },
    resetForm() {
      this.text = '';
    }
  }
}
</script>

<template>
  <div>
    <Button type="submit" label="Zapisz" icon="pi pi-check" @click="onSubmit"/>
    <div class="field mb-5 mt-5">
      <span class="p-float-label">
        <InputText id="category" v-model="category" type="text" class="input"/>
        <label for="category">Category</label>
      </span>
    </div>
    <div class="field mb-5 ">
      <span class="p-float-label">
        <InputText id="subCategory" v-model="subCategory" type="text" class="input"/>
        <label for="subCategory">SubCategory</label>
      </span>
    </div>
    <div class="field mb-5">
      <span class="p-float-label">
        <InputText id="name" v-model="name" type="text" class="input"/>
        <label for="name">Name</label>
      </span>
    </div>
    <div class="field mb-5">
      <span class="p-float-label">
        <InputText id="title" v-model="title" type="text" class="input"/>
        <label for="title">Title</label>
      </span>
    </div>
    <div class="solution-container">
      <LatexData ref="textLatex" :equ="text"></LatexData>
    </div>
    <div class="field">
          <span class="p-float-label">
            <Textarea class="textarea" v-model="rawText" @input="renderText(rawText)"></Textarea>
            <label for="rawSolution">Tekst</label>
          </span>
    </div>
  </div>
</template>

<style scoped>
.field {
  margin-bottom: 1.5em;
}
.input {
  width: 20em;
}
.textarea {
  min-width: 30rem;
  min-height: 20rem;
}

@media screen and (max-width: 640px) {
  .textarea {
    min-width: 100%;
  }
}
</style>