<script>
export default {
  name: "Terms"
}
</script>

<template>
  <div class="card card-filter">
    <div class="card card-panel">
      <div class="card-body">
        <div class="main-div">
          <div class="div-center">
            <h2 class="title">Regulamin serwisu internetowego losujzadanie.pl należącego do PB Code Przemysław
              Biliński</h2>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Najważniejsze postanowienia regulaminu:</h2>
            </div>
            <div class="single-term">
              1. Konto jest aktywowane natychmiast po zaksięgowaniu płatności na koncie Administratora lub po
              aktywowaniu pakietu darmowego.
            </div>
            <div class="single-term">
              2. Materiały zamieszczone na losujzadanie.pl z wyjątkiem zadań pochodzących z matur są chronione prawem
              autorskim i nie mogą być kopiowane i wykorzystywane w inny sposób, jak tylko bezpośredni odczyt na
              losujzadanie.pl
            </div>
          </div>
          <div class="single-term">
            <div class="div-center">
              <h2 class="term-header center">Definicje</h2>
            </div>
            1. <span class="font-bold">Usługodawca: PB Code Przemysław Biliński </span>
            NIP 9512468521, REGON 381159640 z siedzibą ul.Wierna 23B/44, 03-890 Warszawa
          </div>
          <div class="single-term">
            2. <span class="font-bold">Serwis</span>
            oznacza platformę internetową udostępniającą zadania z matematyki oraz związane z nimi treści edukacyjne.
          </div>
          <div class="single-term">
            3. <span class="font-bold">Usługobiorca/Subskrybent:</span>
            oznacza osobę fizyczną lub prawną, która opłaciła subskrypcję i uzyskała dostęp do płatnych zasobów serwisu.
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Subskrypcja</h2>
            </div>
            <div class="single-term">
              1. Dostęp do płatnych zasobów serwisu wymaga dokonania płatności jednorazowej.
            </div>
            <div class="single-term">
              2. Płatność jednorazowa na 30/90/180 dni może być opłaca za pośrednictwem różnych metod płatności dostępny w
              serwisie, karta
              kredytowa/debetowa, GPay lub BLIK.
            </div>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Prawa i obowiązki subskrybenta</h2>
            </div>
            <div class="single-term">
              1. Subskrybent ma prawo korzystać z płatnych zasobów serwisu zgodnie z warunkami subskrypcji.
            </div>
            <div class="single-term">
              2. Subskrybent zobowiązuje się do nieudostępniania swojego konta ani danych logowania osobom trzecim.
            </div>
            <div class="single-term">
              3. Subskrybent ponosi odpowiedzialność za wszystkie czynności dokonane przy użyciu jego konta.
            </div>
            <div class="single-term">
              4. Subskrybent zobowiązuje się do przestrzegania niniejszego regulaminu oraz wszelkich przepisów
              obowiązujących.
            </div>
            <div class="single-term">
              5. Subskrybent ma prawo do usunięcia swojego konta z serwisu, w tym celu należy skontaktować się drogą
              mailową na adres: <a class="link" href="mailto:kontakt@losujzadanie.pl">kontakt@losujzadanie.pl</a> z
              dopiskiem "Usuniecie konta - {twój adres mailowy}"
            </div>
          </div>

          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Zawartość serwisu</h2>
            </div>
            <div class="single-term">
              1. Rozwiązania zadań z matematyki oraz inne treści edukacyjne udostępniane w serwisie są chronione prawami
              autorskimi.
            </div>
            <div class="single-term">
              2. Zadania z matematyki pochodzą z matur CKE z poszczególnych lat i są we właściwy sposób oznaczone, link
              do arkuszy https://cke.gov.pl/arkusze/egzamin-maturalny/
            </div>
            <div class="single-term">
              3. Zakazane jest kopiowanie, reprodukowanie lub dystrybuowanie treści udostępnionych w serwisie bez zgody
              właściciela praw autorskich.
            </div>
            <div class="single-term">
              4. Serwis zastrzega sobie prawo do zmiany zawartości serwisu oraz warunków subskrypcji w dowolnym
              momencie.
            </div>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Polityka prywatności</h2>
            </div>
            <div class="single-term">
              1. Rozwiązania zadań z matematyki oraz inne treści edukacyjne udostępniane w serwisie są chronione prawami
              autorskimi.
            </div>
            <div class="single-term">
              2. Serwis dba o prywatność swoich użytkowników i stosuje odpowiednie środki techniczne i organizacyjne w
              celu ochrony danych osobowych.
            </div>
            <div class="single-term">
              3. Dane osobowe subskrybentów są przetwarzane zgodnie z obowiązującymi przepisami prawa oraz polityką
              prywatności serwisu.
            </div>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Ochrona danych osobowych</h2>
            </div>
            <div class="single-term">
              1. Administratorem danych osobowych Subskrybentów jest: PB CODE PRZEMYSŁAW BILIŃSKI ul.
              Wierna 23B/44 03-890 Warszawa, NIP 9512468221, REGON 381159640
            </div>
            <div class="single-term">
              2. Dane osobowe Usługobiorców przetwarzane są w celu zawarcia oraz wykonania Umowy. Administrator może
              przetwarzać dane osobowe Usługobiorców w celu marketingu bezpośredniego produktów i usług. Informacje na
              temat podstaw prawnych przetwarzania danych osobowych przez Administratora, w tym realizowanych przez
              Administratora prawnie uzasadnionych interesów, dostępnych jest pod adresem https://losujzadanie.pl/
            </div>
            <div class="single-term">
              3. Podstawą prawną przetwarzania danych osobowych Usługobiorców są:
              <ul>
                <li>niezbędność przetwarzania do zawarcia i wykonania Umowy,</li>
                <li>realizacja obowiązków prawnych ciążących na Administratorze, w tym wynikających z ustawy o
                  świadczeniu usług drogą elektroniczną
                </li>
                <li>realizacja prawnie uzasadnionych interesów Administratora (m.in. komunikacja z Usługobiorcą poprzez
                  strony internetowe i inne środki komunikacji elektronicznej),
                </li>
                <li>realizacja prawnie uzasadnionych interesów Administratora (tj. prezentowanie ofert produktów oraz
                  usług). Dane Usługobiorcy mogą być przekazywane:
                  <ul>
                    <li>podmiotom działającym na podstawie przepisów prawa (organom publicznym), w tym m.in.
                      Prokuraturze, Policji, Krajowej Administracji Skarbowej oraz Urzędowi Skarbowemu
                    </li>
                    <li>zgodnie z realizacją obowiązków prawnych ciążących na Administratorze,</li>
                    <li>podmiotom świadczącym na rzecz Administratora usług informatycznych, w zakresie niezbędnym do
                      realizacji tych usług przez Usługodawcę.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Okres obowiązywania umowy</h2>
            </div>
            <div class="single-term">
              1. Umowa o odpłatne świadczenie subskrypcji oraz Usług dodatkowych jest zawarta na okres wskazany w
              Zamówieniu, a po zakończeniu ulega automatycznemu przedłużeniu o wybrany plan subskrypcji.
            </div>
            <div class="single-term">
              2. Umowa o bezpłatne świadczenie subskrypcji w okresie testowym oraz dostępu do Usług dodatkowych jest
              zawarta na okres wskazany w Zamówieniu i nie przechodzi w umowę o odpłatne świadczenie Usługi subskrypcji.
            </div>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Odstąpienie od umowy</h2>
            </div>
            <div class="single-term">
              1. Zgodnie z ustawą z dnia 30.05.2014 r. o prawach konsumenta (Dz.U. z 2020 r. poz. 287 z późn. zm.)
              Usługobiorcom będącymi Konsumentami przysługuje prawo do odstąpienia od Umowy bez podania przyczyny i bez
              ponoszenia dodatkowych kosztów, w terminie 14 dni od daty Rejestracji. Do odstąpienia od Umowy konieczne
              jest złożenie Usługodawcy stosownego oświadczenia. W tym celu można skorzystać skontaktować sie drogą
              mailową na adres: <a class="link" href="mailto:kontakt@losujzadanie.pl">kontakt@losujzadanie.pl</a>.
            </div>
            <div class="single-term">
              2. Usługodawca niezwłocznie lecz nie później niż w terminie 14 dni od dnia otrzymania oświadczenia
              Konsumenta o odstąpieniu od Umowy, dokonuje zwrotu wszystkich dokonanych przez Konsumenta płatności.
            </div>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Reklamacje</h2>
            </div>
            <div class="single-term">
              1. Reklamacje dotyczące funkcjonowania Strony internetowej powinny być zgłaszane pocztą elektroniczną na
              adres: <a class="link" href="mailto:kontakt@losujzadanie.pl">kontakt@losujzadanie.pl</a>.
            </div>
            <div class="single-term">
              2. Termin rozpatrzenia reklamacji wynosi 14 (słownie: czternaście) dni od dnia otrzymania reklamacji przez
              Usługodawcę.
            </div>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Polityka cookies</h2>
            </div>
            <div class="single-term">
              1. Korzystanie ze Strony internetowej wymaga od Usługobiorcy akceptacji niniejszej Polityki Cookies jak
              również umieszczenia po stronie przeglądarki internetowej Użytkownika tzw. Cookies – tj. małych plików
              tekstowych z informacjami wykorzystywanymi przez strony internetowe, łączące się z przeglądarką
              Usługobiorcy. Podczas korzystania ze Strony internetowej Usługodawca pobiera od przeglądarek Usługobiorców
              i zapisuje informacje, które mogą zawierać dane osobowe. Usługodawca pozyskuje te informacje korzystając z
              plików cookies.
            </div>
            <div class="single-term">
              2. Okres aktywności plików cookies :
              czasowe / tymczasowe – to te pliki, które są automatycznie usuwane po określonej dacie ważności.
              stałe - to te pliki, które są aktywne w przeglądarce Usługobiorcy do chwili ich usunięcia przez
              Usługobiorcę, co może zostać przez niego dokonane w dowolnym czasie.
            </div>
          </div>
          <div class="term">
            <div class="div-center">
              <h2 class="term-header center">Postanowienia końcowe</h2>
            </div>
            <div class="single-term">
              1. Niniejszy regulamin jest wiążący dla wszystkich użytkowników serwisu.
            </div>
            <div class="single-term">
              2. Serwis zastrzega sobie prawo do zmiany niniejszego regulaminu w dowolnym czasie.
            </div>
            <div class="single-term ">
              3. W przypadku istotnych zmian w regulaminie, subskrybenci zostaną o nich poinformowani w zakładce
              "Aktualności".
            </div>
            <div class="single-term">
              4. Każdy subskrybent jest zobowiązany do regularnego sprawdzania warunków korzystania z serwisu.
            </div>
            <div class="single-term">
              5. W przypadku naruszenia postanowień niniejszego regulaminu, serwis zastrzega sobie prawo do
              zablokowania dostępu subskrybenta do płatnych zasobów serwisu lub nawet do rozwiązania umowy o
              subskrypcję.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-filter {
  margin-top: 1rem;
  justify-content: center;
  text-align: left;
}

.div-center {
  text-align: center;
}

.single-term {
  margin-bottom: 0.5em;
  line-height: 1.6;
}

.main-div {
  padding: 1rem;
  color: #4b5563;
}

.term {
  margin-bottom: 2em;
}

ul {
  margin-top: 0;
}

.link {
  color: #8B5CF6;
}

@media screen and (max-width: 640px) {
  .main-div {
    padding: 0.5rem;
  }

  .title {
    font-size: 1.2em;
  }

  .term-header {
    font-size: 1.2em;
  }

  .single-term {
    font-size: 0.8em;
  }
}

</style>