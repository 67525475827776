<script>
export default {
  name: "ProgressBar",
  props: {
    value: Number,
    options: JSON
  },
  data() {
    return {
      barStyle: ''
    }
  }
}
</script>

<template>
  <div class="bar-container">
    <div class="bar" :style="{width: value + '%'}">
    </div>
  </div>
  <span class="percent">
            {{ value }} %
      </span>
</template>


<style scoped>
.bar-container {
  border: 0 none;
  height: 1.5rem;
  background: #e5e7eb;
  border-radius: 6px;
}

.bar {
  background: #8B5CF6;
  width: 0;
  height: 24px;
  border-radius: 9px;
}
.percent{
  font-weight: bold;
  color: #4b5563;
}
</style>