<script>
export default {
  name: "TaskQuestionQuery",
  props: {
    records: Array
  }
}
</script>

<template>
  <DataTable :value="records" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
             paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
             paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
             currentPageReportTemplate="{first} - {last} z {totalRecords}">
    <Column field="id" header="Id"></Column>
    <Column field="username" header="Username"></Column>
    <Column field="email" header="Email"></Column>
    <Column field="types" header="Types"></Column>
    <Column field="taskId" header="Task Id"></Column>
    <Column field="unit" header="Unit"></Column>
    <Column field="createdDate" header="Created Date"></Column>
    <Column field="units" header="Units"></Column>
  </DataTable>
</template>
<style scoped>

</style>