<script>
import axios from "axios";
export default {
  name: "PaymentSuccess",
  created() {
    this.sessionRedirect();
  },
  methods: {
    async sessionRedirect() {
      const sessionId = this.$route.query.session_id;
      const token = this.getCookie('lz_token');
      const response = await axios.post('/users/updateUser', {token: token, sessionId: sessionId});
      if(response.data.status === 400) {
        this.$router.push('/profil');
        this.showToast('error', 'Wystąpił błąd podczas przetwarzania płatności', '', 2000);

      } else {
        this.$router.push('/losuj-zadanie');
        this.showToast('success', 'Płatność zakończona pozytywnie', '', 2000);

      }

    },
    getCookie(name) {
      return this.$cookies.get(name);
    },
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }

  }
}
</script>

<template>
</template>

<style scoped>

</style>