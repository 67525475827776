import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import ConfirmationService from 'primevue/confirmationservice';
import MathJax, { initMathJax, renderByMathjax } from "mathjax-vue3";

import 'primevue/resources/themes/lara-light-purple/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import Button from 'primevue/button';
import Card from 'primevue/card';
import Toast from 'primevue/toast';
import Menubar from 'primevue/menubar';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import Textarea  from 'primevue/textarea';
import Menu  from 'primevue/menu';
import ConfirmDialog from 'primevue/confirmdialog';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import RadioButton from 'primevue/radiobutton';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import SelectButton from 'primevue/selectbutton';

let localhostURL = 'http://localhost:5000/';
let url = 'https://api.losujzadanie.pl/';
let devUrl = 'http://api.dev.losujzadanie.pl/';
axios.defaults.baseURL = url;

function onMathJaxReady() {
    const el = document.getElementById("elementId");
    renderByMathjax(el);
}
initMathJax({}, onMathJaxReady);

const app = createApp(App);
app.config.errorHandler = function (err, vm, info) {
    return {
        err: err,
        info: info
    }
}

app.use(router);
app.use(ToastService);
app.use(VueCookies, {expires: '24h'});
app.use(ConfirmationService);
app.use(MathJax);
app.use(PrimeVue);

app.component('Button', Button);
app.component('Card', Card);
app.component('Toast', Toast);
app.component('Menubar', Menubar);
app.component('Checkbox', Checkbox);
app.component('InputText', InputText);
app.component('Dropdown', Dropdown);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('FileUpload', FileUpload);
app.component('Image', Image);
app.component('Textarea', Textarea);
app.component('Menu', Menu);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Accordion',Accordion);
app.component('AccordionTab',AccordionTab);
app.component('RadioButton',RadioButton);
app.component('TabMenu',TabMenu);
app.component('Tag',Tag);
app.component('SelectButton',SelectButton);

app.mount('#app');
